@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;1,100&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsla(120, 96%, 9%, 0.047);
  transition: 0,8s linear;
}
/* #root {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
} */

.loading {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function:  linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to  {
    transform: rotate(360deg);
  }
}

.pulse {
  animation-name: pulso;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function:  ease-in-out;
}

@keyframes pulso {
  from{
    opacity: 30;
  }
    to{
      opacity: 90;
    }
}

.cards {
  background-color: #fff;
}

.check {
  color: rgba(182, 181, 181, 0.678);
}

.check::before{
  color: rgba(231, 178, 4, 0.918);
}